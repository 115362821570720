// import './App.css';
import { Routes, Route, useLocation } from "react-router";
import NavBar from './components/NavBar';
import Home from './pages/home';
import DevPortfolio from './pages/dev-portfolio';
import DesignPortfolio from './pages/design-portfolio';
import { useTheme } from './ThemeContext';
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  const { darkMode } = useTheme();

  return (
          
    <>
      <div className={`min-h-screen flex flex-col max-w-screen justify-start ${ darkMode ? 'dark bg-slate-950' : 'rainbow'}`}>     
      <NavBar />

        <main className='w-full flex-col justify-items-top transition-all duration-300 '>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route path="/dev-portfolio" element={<DevPortfolio />} />
            <Route path="/design-portfolio" element={<DesignPortfolio />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        </AnimatePresence>
        </main>
      </div>

    </>
    
  );
}

export default App;
