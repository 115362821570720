import React from 'react';
import { CaseStudies } from '../constants/constants.js';
import ButtonLink from './ButtonLink';
import { div } from 'framer-motion/client';

const CaseStudiesComponent = () => {
  return (
    <div className='p-2 mt-10'>
        <div>
            <h3 className='text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-500'>Coding Portfolio</h3>
            <p className='text-left text-md my-5 py-5 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-500'>These are coding projects I've developed and/or collaborated on through freelance work, my career, and at university.</p>
        </div>

        <div className='min-h-max w-full mb-5 pb-2'>

            {CaseStudies.map((study, index) => (
                <div key={index} className='p-5 mb-10 rounded-lg shadow-lg border-2 md:p-10  border-gray-300 dark:border-gray-600'>

                <h2 className='text-2xl mb-4 font-bold md:text-4xl md:mb-10 text-pearl-950 dark:text-pearl-500'>{study.title}</h2>

                <p className='mt-2 text-lg md:text-2xl md:pb-6 text-pearl-950 dark:text-pearl-500'>
                    <strong>Objective:</strong> {study.overview.objective}
                </p>
                <p className='mt-1  pb-5 text-lg md:text-2xl md:pb-8 text-pearl-950 dark:text-pearl-500'>
                    <strong>Scope:</strong> {study.overview.scope}
                </p>

                <div className='mt-5 pb-5'>
                    <h3 className='text-lg pb-2 font-semibold text-pearl-950 dark:text-pearl-500'>Challenges</h3>
                    <ul className='list-disc ml-5 list-inside text-pearl-950 dark:text-pearl-500'>
                    {study.challenges.map((challenge, i) => (
                        <li key={i} className='pb-2'>{challenge}</li>
                    ))}
                    </ul>
                </div>

                <div className='mt-5 pb-5'>
                    <h3 className='text-lg pb-2 font-semibold text-pearl-950 dark:text-pearl-500'>Solution</h3>
                    <ul className='list-disc ml-5 list-inside text-pearl-950 dark:text-pearl-500'>
                    {study.solution.steps.map((step, i) => (
                        <li key={i} className='pb-2'>{step}</li>
                    ))}
                    </ul>
                </div>

                <div className='mt-5 pb-5'>
                    <h3 className='text-lg pb-2 font-semibold text-pearl-950 dark:text-pearl-500'>Implementation</h3>
                    <ul className='list-disc ml-5 list-inside text-pearl-950 dark:text-pearl-500'>
                    {study.implementation.process.map((step, i) => (
                        <li key={i} className='pb-2'>{step}</li>
                    ))}
                    </ul>
                </div>

                <div className='my-5 py-5 px-6 border-2 rounded-lg w-full bg-gray-100 dark:bg-slate-800 shadow-lg'>
                    <h3 className='text-lg pb-2 font-semibold text-pearl-950 dark:text-pearl-500'>Outcome</h3>
                    <ul className='ml-5 list-disc list-inside text-pearl-950 dark:text-pearl-500'>
                        {study.outcome.improvements.map((improvement, i) => (
                        <li key={i} className='pb-2'>{improvement}</li>
                        ))}
                    </ul>
                    {/* <p className='mt-2 text-pearl-950 dark:text-pearl-500'>
                        <strong>Feedback:</strong> {study.outcome.feedback}
                    </p>
                    <p className='text-pearl-950 dark:text-pearl-500'>
                        <strong>Impact:</strong> {study.outcome.impact}
                    </p> */}
                    <hr className='my-5'/>
                    <h3 className='text-lg pb-2 font-semibold text-pearl-950 dark:text-pearl-500'>Takeaways</h3>
                    <ul className='list-disc pb-3 ml-5 pr-3 list-inside text-pearl-950 dark:text-pearl-500'>
                    {study.takeaways.map((takeaway, i) => (
                        <li key={i} className='pb-2'>{takeaway}</li>
                    ))}
                    </ul>
                </div>

                    <div className='mt-[3rem]'>
                        <h2 className='text-2xl mb-4 font-bold text-pearl-950 dark:text-pearl-500'>Media</h2>

                        <div className='grid grid-cols-1 gap-2 pb-10 md:grid-cols-2 md:gap-10'>
                        {study.media.map((item, i) => {
                            if (item.type === 'image') {
                            return (
                                <div className='p-5 my-4 rounded-lg shadow-lg w-full min-h-max md:max-w-full dark:border-slate-700 border-2'>
                                    <h4 className='font-semibold pb-2  dark:text-pearl-500'>{item.alt}</h4>
                                    <img
                                    key={i}
                                    src={item.url}
                                    alt={item.alt}
                                    
                                    />                                
                                </div>
                            );
                            } else if (item.type === 'video') {
                            return (
                                <div className='p-5 my-4 rounded-lg shadow-lg w-full min-h-max md:max-w-full dark:border-slate-700 border-2'>
                                    <h4 className='font-semibold pb-2  dark:text-pearl-500'>{item.alt}</h4>
                                    <video
                                        key={i}
                                        controls
                                        poster={item.poster}
                                        className='block mb-1'
                                        style={{ objectFit: 'contain', width: '100%', maxHeight: '60vh' }}
                                    >
                                    <source src={item.url} type='video/mp4' />
                                    Your browser does not support the video tag.
                                    </video>
                                </div>
                            );
                            }
                            return null;
                        })}
                        </div>

                    </div>
                </div>
            ))}


        </div>
    </div>


  );
};

export default CaseStudiesComponent;
