import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const importAll = (requireContext) => requireContext.keys().map(requireContext);
const images = importAll(require.context('../media/Artwork/Logos', false, /\.(png|jpe?g|svg)$/));

const LogoGallery = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const closeModal = () => setSelectedIndex(null);

  const nextImage = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="p-5 mt-10">
      <div>
      <h3 className='text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-500'>Brand Design</h3>
      <p className='text-left text-md my-5 py-5 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-500'>For the duration of my design career I've also had the pleasure of working on branding campaigns and logos for events, social media, and small businesses. These are examples of the most recent brand projects I've worked on.</p>
      </div>
      <div className="flex flex-col flex-1 gap-10 py-10 justify-between lg:flex-row lg:flex-wrap">
        {images.map((image, index) => {
          const capText = image.split('/').pop().split('.')[0].replace(/-/g, ' ');

          return (
            <div
              key={index}
              className="basis-[31%] rounded-lg p-6 border-2 border-pearl-50 bg-pearl-50 dark:bg-slate-900 cursor-pointer"
              onClick={() => setSelectedIndex(index)}
            >
              <div className="flex flex-wrap flex-col h-auto items-center justify-center">
                <h2 className="w-full pb-6 font-bold text-pearl-950 dark:text-white dark:bg-slate-900">
                  {capText}
                </h2>
                <img
                  src={image}
                  alt={`Portfolio Item ${capText}`}
                  className="rounded-sm overflow-hidden w-full h-auto object-cover"
                />
              </div>
            </div>
          );
        })}
      </div>

      {/* Modal */}
      <AnimatePresence>
        {selectedIndex !== null && (
          <>
            {/* Overlay */}
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-[1000]" // High z-index
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            />

            {/* Modal Content */}
            <motion.div
              className="fixed inset-0 flex items-center justify-center z-[1100]" // Higher z-index than the overlay
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <div className="bg-white dark:bg-slate-900 rounded-lg max-w-3xl w-full shadow-lg max-h-[90vh] flex flex-col items-center justify-center relative">
                {/* Close Button */}
                <button
                  className="absolute top-4 right-4 text-pearl-950 dark:text-pearl-500 text-xl"
                  onClick={closeModal}
                >
                  <FaTimes />
                </button>

                {/* Navigation Arrows */}
                <button
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-pearl-950 dark:text-pearl-500 text-2xl"
                  onClick={prevImage}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-pearl-950 dark:text-pearl-500 text-2xl"
                  onClick={nextImage}
                >
                  <FaArrowRight />
                </button>

                {/* Image and Caption */}
                <img
                  src={images[selectedIndex]}
                  alt={`Logo for ${selectedIndex + 1}`}
                  className="p-6 justify-self-center rounded-lg overflow-hidden w-auto h-auto max-h-[80vh]"
                />
                <p className="text-center mt-2 mb-6 text-pearl-950 dark:text-pearl-500">
                  {images[selectedIndex].split('/').pop().split('.')[0].replace(/-/g, ' ')}
                </p>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LogoGallery;
