import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [darkMode, setIsDarkMode] = useState(false);

    // Check and apply system dark mode preference
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        // Set initial state based on system preference
        setIsDarkMode(mediaQuery.matches);

        // Update dark mode when system preference changes
        const handleChange = (e) => {
            setIsDarkMode(e.matches);
        };

        mediaQuery.addEventListener('change', handleChange);

        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    // Update document body class when dark mode changes
    useEffect(() => {
        document.body.classList.toggle('darkMode', darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };

    // useEffect(() => {
    //   const timeNow = new Date();
    //   const tnHour = timeNow.getHours();
  
    //   if (tnHour > 18 || tnHour < 6) {
    //     setdarkMode(true);
    //   } else {
    //     setdarkMode(false);
    //   }
    // }, []);

    return (
        <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => React.useContext(ThemeContext);
