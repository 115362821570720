import React from 'react';
import { FaTools } from 'react-icons/fa'

const videos = [
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Grant-Winners.mp4",
        caption: "Grant Winner Reel Announcement",
        description: "Blender, After Effects",
        mode: "portrait"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Giveaway-Reel.mp4",
        description: "Blender, After Effects",
        caption: "Giveaway Reel",
        mode: "portrait"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Deposit-Reel.mp4",
        description: "Blender, After Effects, Photoshop",
        caption: "Share Product Story Ad",
        mode: "portrait"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Gold-Standard-Insta.mp4",
        description: "Blender, After Effects, Photoshop",
        caption: "Deposit Product Story Ad",
        mode: "portrait"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Motion-Graphics-Reel.mp4",
        description: "Blender, After Effects, Photoshop, Premiere Pro",
        caption: "Motion Graphics Compilation",
        mode: "landscape"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/PSOM-YouTube-Teaser.mp4",
        description: "Premiere Pro, After Effects",
        caption: "YouTube Teaser Trailer",
        mode: "landscape"
    },
];

const VideoGallery = () => {

  return (
    <div className="p-5 mt-10">
      <div>
        <h3 className="text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-500">Motion Graphics</h3>
        <p className="text-left text-md my-5 py-5 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-500">
          Explore my most recent motion graphic and video production work.
        </p>
      </div>
      <div className="flex flex-col flex-1 gap-10 py-10 justify-between lg:flex-row lg:flex-wrap">
        {videos.map((video, index) => {
          const vidMode = video.mode === "portrait";

          return (
            <div key={index} className={`rounded-lg p-6 border-2 border-pearl-50 bg-pearl-50 dark:bg-slate-900 ${ vidMode ? "basis-[24%] lg:basis-[23%]" : "w-full lg:w-[48.5%]"}`} >
              <div className="flex flex-wrap flex-col h-auto items-center justify-center">
                <h2 className="w-full pb-2 font-bold text-pearl-950 dark:text-white dark:bg-slate-900">
                  {video.caption}
                </h2>
                <div className='w-full justify-start pb-6'>
                <p className='dark:text-white flex-row flex'><FaTools className='mr-2 translate-y-1'/> {video.description}</p>
                </div>
                <video controls autoPlay={false} muted={true} loop={false} className="rounded-sm overflow-hidden w-full h-auto object-cover" style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={video.url} type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideoGallery;
