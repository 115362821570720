import React from 'react';
import Projects from '../components/Projects'
import CaseStudiesComponent from '../components/CaseStudies';
import { motion } from 'framer-motion';


const pageVariants = {
  initial: { opacity: 0, x: '-100vw' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100vw' },
};

const DevPortfolio = () => {

  return (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}  >
    <div>
          <section className='min-h-fit mb-12 px-5 justify-self-center lg:w-10/12'>

            <CaseStudiesComponent></CaseStudiesComponent>
            <Projects></Projects>

          </section>
    </div>
    </motion.div>

  );
};

export default DevPortfolio;
