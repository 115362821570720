import project1 from '../media/android-001.jpg'
import project2 from '../media/project-001.jpg'
import project3 from '../media/project-002.jpg'
import project5 from '../media/project-004.jpg'
import project6 from '../media/project-005.jpg'

import careers1 from '../media/casestudies/Careers-Mobile-Before.jpg'
import careers2 from '../media/casestudies/Careers-Mobile-After.jpg'
import careers3 from '../media/casestudies/Careers-Desktop-Before.jpg'
import careers4 from '../media/casestudies/Careers-Desktop-After.jpg'

import ppartner1 from '../media/casestudies/Preferred-Partner-Demo-1.jpg'
import ppartner2 from '../media/casestudies/Preferred-Partner-Demo-2.jpg'

import student1 from '../media/casestudies/Student-Card-Page.jpg'
import student2 from '../media/casestudies/Student-Card-Page-Mobile.jpg'
import student3 from '../media/casestudies/Student-Visa-Blue.jpg'


const PortfolioProjects = [
  {
    image: `${project5}`,
    alt: "",
    archive: false,
    title: "GDIT ATOaaS System Protoype",
    link: "https://github.com/awil/NIST_Automation",
    description:
      "I collaborated on a senior project to develop an automated Authorization to Operate as a Service (ATOaaS) solution for GDIT, streamlining and simplifying the auditing process. My role involved developing/coding an object oriented backend written in PHP and MySQL, setting up the site on an AWS server, and testing/debugging.",
      btnText: "View on GitHub",
  },
  {
    image: `${project1}`,
    alt: "",
    archive: false,
    title: "Catchin' Crawfish,",
    link: "https://vimeo.com/manage/videos/836094363",
    description:
      "As an individual project, I designed and developed an Android App called \"Catchin' Crawfish,\" a quirky match-three game where players aim to catch crawfish.",
    btnText: "Watch Demo",
  },
  {
    image: `${project6}`,
    alt: "",
    archive: false,
    title: "Server-Side Project",
    link: "https://github.com/Smasson76/SportsPro-Technical-Support",
    description:
      "A collaborative group project, my role involved implementing the server-side code using PHP and MySQL, ensuring seamless communication and data management for the SportsPro Technical Application.",
    btnText: "View on GitHub",
  },
  {
    image: `${project2}`,
    alt: "",
    archive: false,
    title: "Walker OMV Express",
    link: "https://walkeromv.com/",
    description:
      "I developed the website for Walker OMV Express to display their services, location and a contact page.",
    btnText: "Visit Walker OMV Express",
  },
  {
    image: `${project3}`,
    alt: "",
    archive: false,
    title: "@TheBarberShop",
    link: "http://atthebarber.shop",
    description:
      "I designed and developed a professional and user-friendly website for a client's barbershop, showcasing their services and team. Additionally, I designed the branding and signage for their office.",
    btnText: "Visit @TheBarberShop",
  },

];


const oldStudies = [
  {
    title: "Case Study: Revamping the Careers Page",
    overview: {
      objective: "Revamp the careers page to showcase company culture and appeal, working around CMS restrictions.",
      scope: "Custom coding JavaScript and CSS to enhance functionality and visual appeal."
    },
    challenges: [
      "Outdated design and limited customization.",
      "Creating dynamic benefits and core values sections.",
      "Need for dynamic functionality and proper video playback support on iOS in Safari."
    ],
    solution: {
      steps: [
        "Analyzed the existing page to develop a better flow and hierarchy.",
        "Implemented custom JavaScript for dynamic elements.",
        "Learned and utilized AWS S3 buckets and CloudFront to host videos, ensuring proper playback on iOS in Safari."

      ]
    },
    implementation: {
      process: [
        "To figure out how to work around the CMS I had to 'clone' the page locally using a web archive and somewhat rebuilding the page for restructuring it with JavaScript.",
        "I played around with different layouts trying to see which flow would be best to show off the Benefits and Core Values sections.",
        "Configured AWS S3 and CloudFront to host and deliver videos.",
        "Resolved Safari-specific playback issues with video hosting.",
        "Validated usability with cross-browser testing."      ]
    },
    outcome: {
      improvements: [
        "Responsive design for all devices.",
        "Streamlined job search functionality.",
        "Faster load times and better user experience.",
        "Smooth video playback across all browsers, including Safari."
      ],
      feedback: "Positive stakeholder approval on navigation and design with minimal changes requested.",
      impact: "Exceeded project goals by improving user engagement and functionality."
    },
    takeaways: [
      "Learned to overcome CMS limitations with custom code.",
      "Gained experience in AWS S3 and CloudFront for hosting and media delivery.",
      "Enhanced problem-solving skills for cross-browser compatibility challenges.",
      "This experience will definitely help on future projects with similar constraints due to pre-existing CMS limitations."
    ],
    media: [
      {
        type: "image",
        url: "https://example.com/screenshot1.png",
        alt: "Screenshot of the revamped Careers Page design."
      },
      {
        type: "image",
        url: "https://example.com/screenshot2.png",
        alt: "Screenshot showing the Benefits section layout."
      },
      {
        type: "image",
        url: "https://example.com/screenshot3.png",
        alt: "Screenshot of the Core Values section."
      },
      {
        type: "video",
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Careers-Mobile-Before_1.mp4",
        alt: "Video demo of the Careers Page in action.",
        poster: "https://example.com/video-poster.png" // Optional poster image for the video
      }
    ]
  }
];


const CaseStudies = [
  {
    title: "Case Study: Revamping the Careers Page",
    overview: {
      objective: "Revamp the careers page to showcase company culture and appeal, working around CMS restrictions.",
      scope: "Custom coding JavaScript and CSS to enhance functionality and visual appeal."
    },
    challenges: [
      "Outdated design and limited customization.",
      "Creating dynamic benefits and core values sections.",
      "Need for dynamic functionality and proper video playback support on iOS in Safari."
    ],
    solution: {
      steps: [
        "Analyzed the existing page to develop a better flow and hierarchy.",
        "Implemented custom JavaScript for dynamic elements.",
        "Learned and utilized AWS S3 buckets and CloudFront to host videos, ensuring proper playback on iOS in Safari."

      ]
    },
    implementation: {
      process: [
        "To figure out how to work around the CMS I had to 'clone' the page locally using a web archive and somewhat rebuilding the existing page for restructuring it with JavaScript.",
        "I played around with different layouts trying to see which flow would be best to show off the Benefits and Core Values sections.",
        "Configured AWS S3 and CloudFront to host and deliver videos.",
        "Resolved Safari-specific playback issues with video hosting.",
        "Validated usability with cross-browser testing."      ]
    },
    outcome: {
      improvements: [
        "Responsive design for all devices.",
        "Streamlined job search functionality.",
        "Faster load times and better user experience.",
        "Smooth video playback across all browsers, including Safari."
      ],
      feedback: "Positive stakeholder approval on navigation and design with minimal changes requested.",
      impact: "Exceeded project goals by improving user engagement and functionality."
    },
    takeaways: [
      "Learned to overcome CMS limitations with custom code.",
      "Gained experience in AWS S3 and CloudFront for hosting and media delivery.",
      "Enhanced problem-solving skills for cross-browser compatibility challenges.",
      "This experience will definitely help on future projects with similar constraints due to pre-existing CMS limitations."
    ],
    media: [
      {
        type: "video",
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Careers-Mobile-Before_1.mp4",
        alt: "Original Careers page on mobile.",
        poster: `${careers1}` // Optional poster image for the video
      },
      {
        type: "video",
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Careers-Mobile-After_1.mp4",
        alt: "Revamped Careers page on mobile.",
        poster: `${careers2}` // Optional poster image for the video
      },
      {
        type: "video",
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Careers-Desktop-Before_1.mp4",
        alt: "Original Careers page on desktop.",
        poster: `${careers3}` // Optional poster image for the video
      },
      {
        type: "video",
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Careers-Desktop-After_1.mp4",
        alt: "Revamped Careers page on desktop.",
        poster: `${careers4}` // Optional poster image for the video
      },
    ]
  },
  {
    title: "Case Study: Interactive Map for Preferred Dealer Partners",
    overview: {
        objective: "Develop an interactive map to replace physical printed maps of Preferred Dealer Partners for Pelican's indirect lending program.",
        scope: "Custom JavaScript integration with the Google Maps API to display and manage dynamic map data from CSV files."
    },
    challenges: [
        "Learning the Google Maps API from the ground up.",
        "Integrating and processing data from two CSV files for branch and dealer locations to ensure easy updating by staff.",
        "Preventing the default form behavior enforced by the CMS.",
        "Adding Google Maps API autocomplete for improved user experience.",
        "Branding the Google Maps Markers."
    ],
    solution: {
        steps: [
            "Researched and learned the Google Maps API to dynamically display branch and dealer locations on an interactive map centered around the user's location.",
            "Implemented functionality to parse and load location data from two CSV files using JavaScript.",
            "Created custom JavaScript to override the CMS's default form behavior.",
            "Integrated Google Maps API autocomplete to enhance the search functionality for users."
        ]
    },
    implementation: {
        process: [
            "Learned how to use the Google Maps API, starting with basic examples and progressing to advanced features like markers and info windows.",
            "Parsed CSV files containing branch and dealer location data using JavaScript libraries to format them for map integration.",
            "Wrote custom JavaScript to prevent default CMS form behavior, ensuring seamless functionality for the map's search and filters.",
            "Integrated Google Maps API autocomplete to allow users to quickly search for locations by name or address.",
            "Conducted extensive testing with the Indirect Lending team to ensure the map displayed accurate locations and worked flawlessly across different devices and browsers."
        ]
    },
    outcome: {
        improvements: [
            "Eliminated waste and replaced static printed maps with a fully interactive digital solution.",
            "Users can easily locate nearby branches and dealer partners with an simple search experience.",
            "Improved efficiency by automating data integration from CSV files.",
        ],
        feedback: "Received highly positive feedback from stakeholders for creating a modern, user-friendly alternative to physical maps.",
        impact: "Streamlined the process of locating Preferred Dealer Partners and showcased Pelican's commitment to digital innovation."
    },
    takeaways: [
        "Gained in-depth knowledge of the Google Maps API, including dynamic data handling and autocomplete functionality.",
        "Sharpened my skills in processing and integrating external data sources like CSV files into web apps.",
    ],
    media: [
        {
            type: "image",
            url: `${ppartner2}`,
            alt: "Screencapture of the completed Preferred Partner Page."
        },
        {
            type: "video",
            url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Preferred-Partner-Demo.mp4",
            alt: "Video demo of the interactive map functionality.",
            poster: `${ppartner1}` // Optional poster image for the video
        }
    ]
},
{
  title: "Case Study: Pelican Student Visa Landing Page",
  overview: {
    objective: "Create a competitive, visually engaging product landing page for Pelican’s Student Visa card to rival major banks.",
    scope: "Designed and developed a custom landing page focused on clear messaging, user-friendly navigation, and modern visuals to attract and engage young adults."
  },
  challenges: [
    "Ensuring the design was both modern for a student audience.",
    "Standing out against major banks with significantly larger budgets and resources.",
    "Integrating marketing elements like promotional rates and benefits into a cohesive design.",
    "Navigating CMS limitations to deliver a unique and polished final product."
  ],
  solution: {
    steps: [
      "Designed a layout that emphasized clear calls-to-action and visually appealing sections.",
      "Used custom JavaScript to create dynamic elements, including an FAQ accordion and rate display.",
      "Ensured mobile-first responsiveness to cater to a younger, mobile-focused audience."
    ]
  },
  implementation: {
    process: [
      "Started with a wireframe and mockup phase to define the layout and visual hierarchy.",
      "Developed the page using custom JavaScript and CSS to work around CMS constraints.",
      "Integrated promotional features such as an interactive points slider and visually engaging animations.",
      "Validated cross-browser and device compatibility to ensure a seamless experience."
    ]
  },
  outcome: {
    improvements: [
      "Created a visually engaging, responsive landing page that aligned with Pelican’s branding.",
      "Introduced Pelican's new Student Visa, making it competitive with larger financial institutions.",
    ],
    feedback: "The marketing team praised the page’s clean design and how it effectively communicated the card’s benefits to the target audience.",
    impact: "The landing page successfully positioned Pelican as a serious contender in the student credit card market, setting the stage for future digital product launches."
  },
  takeaways: [
    "Learned how to create a dynamic product page that incorporated design, animations, functionality, and marketing goals.",
    "Great learning experience in designing for a younger, mobile-first audience.",
  ],
  media: [
    {
      type: "video",
      url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Student-Card-Page-Mobile_1.mp4",
      alt: "Video demo Student Visa mobile landing page.",
      poster: `${student2}` // Optional poster image
    },
    {
      type: "image",
      url: `${student3}`,
      alt: "3d rendering of Pelican's Student Visa Card I created."
    },
    {
      type: "video",
      url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Student-Card-Page_1.mp4",
      alt: "Video demo Student Visa desktop landing page.",
      poster: `${student1}` // Optional poster image
    }
  ]
}


];


const CurrentProjects = [
    {
      image: ``,
      alt: "",
    archive: false,
      title: "MarblMatch",
      link: "https://vimeo.com/836067724",
      description:
        "This is a personal project to learn more about iOS/Swift development and the Message App Extension API.",
        btnText: "View on GitHub",
    },
    {
      image: ``,
      alt: "",
    archive: false,
      title: "Catchin' Crawfish,",
      link: "https://vimeo.com/836094363",
      description:
        "I'm working to port my Android game to iOS and add extended functionality to the existing Android app.",
      btnText: "View Demo",
    },
    
  ];


  const skills = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

  const tools = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

  const lenguas = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

export {PortfolioProjects, CurrentProjects, skills, tools, lenguas, CaseStudies};
