import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router";
import './index.css';
import App from './App';
import { ThemeProvider } from './ThemeContext';

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
  <React.StrictMode>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </Router>
      </ThemeProvider>

    {
      /*
        <BrowserRouter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="/dev-portfolio" element={<DevPortfolio />} />
            <Route path="/design-portfolio" element={<DesignPortfolio />} />
            <Route path="/*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>

      
      */
    }
  </React.StrictMode>
  




);