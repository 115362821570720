import React from 'react';
import { NavLink } from 'react-router';
import { BsTerminalFill, BsWrenchAdjustableCircleFill } from 'react-icons/bs'
import portrait from '../media/portraitphoto.jpg'
import { motion } from 'framer-motion';

const pageVariants = {
    initial: { opacity: 0, x: '-100vw' },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: '100vw' },
  };

const BioPage = () => {

  return (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}  >
    <div>
        <section className="min-h-fit mb-12 px-5 justify-self-center lg:w-10/12 flex flex-wrap lg:flex-nowrap flex-row-reverse justify-start">
            {/* Bio Section */}
            <div className="text-left p-5 sm:p-3 max-sm:px-0 mt-10 md:mt-0 lg:w-7/12 xl:w-8/12">
            <h2 className="text-left font-black text-pearl-800 text-3xl py-2 max-sm:pb-0 md:text-6xl dark:text-pearl-50">
                Hi, I'm Aaron!
            </h2>
            <p className="text-left my-5 py-5 text-pearl-800 text-xl leading-[2.15rem] md:leading-[2.4rem] md:text-xl lg:leading-[2.4rem] lg:text-xl xl:leading-[3.4rem] xl:text-2xl max-w-xxl mx-auto dark:text-pearl-50">
                I'm a recent graduate in <strong>Application Development</strong> from Northwestern State University of Louisiana. I'm a <strong>full-stack developer</strong> with <strong>4 years of development experience and 14 years of professional experience.</strong> I love writing code for <em>applications, websites, and utilities.</em> I'm also the proud chihuahua dad of Erma Jean.
            </p>
            <div className="flex justify-start gap-2 w-full">
                <NavLink
                to="/dev-portfolio"
                className="bio-btn flex align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-pearl-900 transition-all duration-300 hover:text-pearl-950 rounded-md dark:text-pearl-50 dark:hover:text-white"
                >
                Coding Portfolio
                </NavLink>
                <NavLink
                to="/design-portfolio"
                className="bio-btn flex align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-pearl-900 transition-all duration-300 hover:text-pearl-950 rounded-md dark:text-pearl-50 dark:hover:text-white"
                >
                Design Portfolio
                </NavLink>
            </div>
            </div>

            {/* Image Section */}
            <div className="mt-2 mb-6 bg-gradient-to-b justify-items-center h-full from-pearl-300 to-pearl-600 rounded-full overflow-hidden lg:w-5/12 lg:mr-10 xl:w-4/12">
            <img
                src={portrait}
                alt="Aaron Williams"
                className="object-cover w-full h-full justify-self-center"
            />
            </div>
        </section>

        <section className='min-h-fit mb-12 px-5 lg:w-10/12 justify-self-center'>
            <div className='rounded-xl  bg-pearl-50 my-4 p-5 md:p-20 dark:bg-slate-900'>
            <h3 className='text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-50'>About Me</h3>
            <p className='text-left text-md my-5 py-5 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-50'>
            For over 17 years, I have worked in multimedia design providing experience in web development, graphic and motion design, and video to companies both large and small. I recently graduated in <strong>Computer Information Systems: Application Development</strong> from <strong>Northwestern State University</strong>, where I studied software development and programming across Android, iOS, MacOS, and Microsoft ecosystems.
            <br/><br/>
            I've been blessed with the opportunity to work on award winning campaigns for a $660M+ asset financial institution, organizations both local and national such as Junior League of Baton Rouge, Walker OMV Express, Athletic Greens, and more. I have conceptualized, designed, and launched responsive sites and landing pages, ideated and designed logos, print materials, annual reports, user interface/experiences, and more—all while using the latest frameworks and design software.            
            </p>
            </div>
            <div className='flex gap-4 flex-col md:flex-row'>
            <div className='text-center shadow-sm p-6 rounded-xl basis-1/2 bg-pearl-50 dark:bg-slate-900'>
                <h2 className='font-bold text-pearl-950 dark:text-pearl-50'><BsTerminalFill className='w-20 h-20 scale-50 mx-auto' /></h2>
                <h3 className='py-4 text-pearl-950 text-xl font-bold dark:text-pearl-50'>Languages</h3>
                <ul className='py-1 text-pearl-950 dark:text-pearl-50'>
                <li>JavaScript</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Java</li>
                <li>Swift</li>
                <li>PHP</li>
                <li>MySQL</li>
                </ul>
            </div>
            <div className='text-center shadow-sm p-6 rounded-xl  basis-1/2 bg-pearl-50 dark:bg-slate-900'>
                <h2 className='font-bold text-pearl-950 dark:text-pearl-50'><BsWrenchAdjustableCircleFill className='w-20 h-20 scale-50 mx-auto' /></h2>
                <h3 className='py-4 text-xl font-bold text-pearl-950 dark:text-pearl-50'>Tools</h3>
                <ul className='py-1 text-pearl-950 dark:text-pearl-50'>
                <li>IDEs (Eclipse, Xcode, Android Developer Studio)</li>
                <li>Database Tools (e.g. SSMS, MySQL Workbench)</li>
                <li>Command Line Interface Tools</li>
                <li>Adobe Creative Suite</li>
                <li>Blender 3D</li>
                </ul>
            </div>
            <div className='text-center shadow-sm p-6 rounded-xl  basis-1/2 bg-pearl-50 dark:bg-slate-900'>
                <h2 className='font-bold text-pearl-950 dark:text-pearl-50'>
                    <BsTerminalFill className='w-20 h-20 scale-50 mx-auto' /></h2>
                <h3 className='py-4 text-pearl-950 text-xl font-bold dark:text-pearl-50'>Skills</h3>
                <div className='flex flex-row basis-full mx-5'>
                    <ul className='basis-1/2 py-1 text-pearl-950 dark:text-pearl-50 text-center'>
                        <li>Agile Development</li>
                        <li>Testing</li>
                        <li>Debugging</li>
                        <li>Git</li>
                        <li>Responsive Design</li>
                        <li>UI/UX Design</li>
                        <li>Database Management</li>
                        <li>RESTful APIs</li>
                    </ul>
                    <ul className='basis-1/2 py-1 text-pearl-950 dark:text-pearl-50 text-center'>
                        <li>Wireframing</li>
                        <li>Prototyping</li>
                        <li>Graphic Design</li>
                        <li>3D Modeling</li>
                        <li>Rendering</li>
                        <li>Multimedia Editing</li>
                        <li>Motion Graphics</li>
                    </ul>
                </div>
            </div>
            <div className='text-center shadow-sm p-6 rounded-xl basis-1/2 bg-pearl-50 md:hidden dark:bg-slate-900'>
                <div className="flex justify-start gap-2 w-full">
                    <NavLink
                    to="/dev-portfolio"
                    className="footer-btn flex align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-pearl-900 transition-all duration-300 hover:text-pearl-950 rounded-md dark:text-pearl-50 dark:hover:text-white" onClick={window.scrollTo({top:0,behavior:'smooth'})}
                    >
                    Coding Portfolio
                    </NavLink>
                    <NavLink
                    to="/design-portfolio"
                    className="footer-btn flex align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-pearl-900 transition-all duration-300 hover:text-pearl-950 rounded-md dark:text-pearl-50 dark:hover:text-white" onClick={window.scrollTo({top:0,behavior:'smooth'})}
                    >
                    Design Portfolio
                    </NavLink>
                </div>
            </div>
            </div>

        </section>
    </div>
    </motion.div>

  );
};

export default BioPage;
