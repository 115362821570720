import React from 'react'
import {PortfolioProjects} from '../constants/constants.js'
import ButtonLink from '../components/ButtonLink'

const Projects = () => {
  return (
    <div className='p-2 mt-10'>
        <div>
        <h3 className='text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-500'>Coding Projects Portfolio</h3>
        <p className='text-left text-md my-5 py-5 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-500'>These are coding projects I've developed and/or collaborated on through freelance work, my career, and at university.</p>
        </div>
        <div className='flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap'>
            { PortfolioProjects.map((card, index) => (
                <div key={index} className='rounded-lg shadow-lg basis-1/3 overflow-hidden flex-1 border-2 border-pearl-50 bg-pearl-50 dark:bg-slate-900'>
                    
                    <div className='h-100'>
                        <h2 className='w-full px-5 pt-5 font-bold md:px-10 md:pt-10 text-pearl-950 dark:text-white dark:bg-slate-900'>
                            {card.title} { 
                                card.archive && (<em className='px-2 py-1 font-bold mr-1 rounded-sm bg-pearl-50 dark:bg-slate-800'>(Archive Project)</em>)
                            }
                        </h2>
                        <p className='px-5 py-5 text-md leading-8 md:px-10 md:py-6 text-pearl-900 dark:text-pearl-500'>
                            {card.description}
                        </p> 
                        <div className='px-2'>
                            { 
                                card.btnText && (<ButtonLink link={card.link} 
                                    text={card.btnText}/>)
                            }
                        </div>
                    </div>
                    
                    <img src={card.image} alt={card.title} className='h-100 object-cover' />
                </div>
            ))}
        </div>
    </div>
    
  )
}

export default Projects