import React from 'react';

const Button = ({ link, text }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
        <button className='proj-btn flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-pearl-900 transition-all duration-300 hover:text-pearl-950 px-2 py-2 rounded-md dark:text-pearl-50 dark:hover:text-white'>
            {text}
        </button>
    </a>
  );
};

export default Button;

